<div class="settings">
	<button
		type="button"
		wndrcIconButton
		title="Show settings"
		size="small"
		fill="clear"
		(click)="onSettingsToggle()"
	>
		<ion-icon [name]="settingsOpened() ? 'settings-outline' : 'settings-sharp'"></ion-icon>
	</button>
	<div
		class="collapse-block"
		[class.collapse-block_opened]="settingsOpened()"
	>
		<div class="settings__actions">
			@if (isAudioOutputConfigurable()) {
				<button
					wndrcButton
					fill="clear"
					color="secondary"
					size="medium"
					type="button"
					class="actions__button"
					(click)="onAudioOutputSelector()"
				>
					Speaker
				</button>
			}
			@if (isAudioInputConfigurable()) {
				<button
					wndrcButton
					fill="clear"
					color="secondary"
					size="medium"
					type="button"
					class="actions__button"
					(click)="onAudioInputSelector()"
				>
					Microphone
				</button>
			}
			@if (isVideoConfigurable()) {
				<button
					wndrcButton
					fill="clear"
					color="secondary"
					size="medium"
					type="button"
					class="actions__button"
					(click)="onVideoInputSelector()"
				>
					Change video
				</button>
			}
		</div>
	</div>
</div>
<div class="settings settings_mobile">
	<button
		type="button"
		wndrcIconButton
		title="Show settings"
		size="small"
		fill="clear"
		[matMenuTriggerFor]="menu"
	>
		<ion-icon [name]="settingsOpened() ? 'settings-outline' : 'settings-sharp'"></ion-icon>
	</button>
	<mat-menu #menu="matMenu">
		@if (isAudioOutputConfigurable()) {
			<button
				mat-menu-item
				type="button"
				(click)="onAudioOutputSelector()"
			>
				Speaker
			</button>
		}
		@if (isAudioInputConfigurable()) {
			<button
				mat-menu-item
				type="button"
				(click)="onAudioInputSelector()"
			>
				Microphone
			</button>
		}
		@if (isVideoConfigurable()) {
			<button
				mat-menu-item
				type="button"
				(click)="onVideoInputSelector()"
			>
				Change video
			</button>
		}
	</mat-menu>
</div>

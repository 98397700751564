import { Injectable } from '@angular/core';

import { CallAuth } from '../models/call-session/call-authorization';
import { CallCredentialsDto, CallAuthDto } from '../dtos/call-auth.dto';

import { CallCredentials } from '../models/call-session/call-credentials';

import { MapperFromDto, MapperToDto } from './mappers';

/** Provide transform functions for call models. */
@Injectable({ providedIn: 'root' })
export class CallMapper implements MapperToDto<CallAuthDto, CallAuth>, MapperFromDto<CallCredentialsDto, CallCredentials> {

	/** @inheritdoc */
	public toDto(authentication: CallAuth): CallAuthDto {
		return {
			session_mode: authentication.isVideoCall ? 'video' : 'audio',
		};
	}

	/** @inheritdoc */
	public fromDto(dto: CallCredentialsDto): CallCredentials {
		return {
			userToken: dto.session_token,
			sessionId: dto.session_id,
		};
	}
}

import { z } from 'zod';

/** API model for call authorization. */
export type CallAuthDto = Readonly<{

	/** Call mode. */
	session_mode: 'audio' | 'video';
}>;

/** Validation schema for call auth response. */
export const callCredentialsSchemaDto = z.object({
	session_token: z.string(),
	session_id: z.string(),
});

/** Call credentials dto. */
export type CallCredentialsDto = z.infer<typeof callCredentialsSchemaDto>;
